const taxonomyTerms = `
terms(first: 50) {
  nodes {
    id
    name
    slug
    ... on Articlecategory {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
    ... on Companytype {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
    ... on Domain {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
    ... on Eventcategory {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
    ... on Jobfunction {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
    ... on Joblevel {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
    ... on Tag {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
    ... on Topic {
      taxonomy {
        node {
          graphqlSingleName
        }
      }
    }
  }
}
`;

export default taxonomyTerms;
