import { gql } from '@apollo/client';
import globalPostFields from '@/lib/wp/_partials/globalPostFields';
import featuredImageFields from '@/lib/wp/media/featuredImage';
import taxonomyTerms from '@/lib/wp/_partials/taxonomyTerms';
import bylineCustomFields from '../_partials/byline';
import primaryTag from '@/lib/wp/_partials/primaryTag';

export const archivePostsFragment = gql`
  fragment ArchivePostFields on Post {
    ${globalPostFields}
    ${featuredImageFields}
    focalPointX
    focalPointY
    readingTime
    contentType {
      node {
        graphqlSingleName
      }
    }
    ${taxonomyTerms}
    ${bylineCustomFields}
    ${primaryTag} 
  }
`;

export const archiveEventsFragment = gql`
  fragment ArchiveEventFields on Event {
    ${globalPostFields}
    ${featuredImageFields}
    focalPointX
    focalPointY
    readingTime
    contentType {
      node {
        graphqlSingleName
      }
    }
    ${taxonomyTerms}
    ${primaryTag} 
  }
`;

export const archiveVideosFragment = gql`
  fragment ArchiveVideoFields on Video {
    ${globalPostFields}
    ${featuredImageFields}
    focalPointX
    focalPointY
    readingTime
    contentType {
      node {
        graphqlSingleName
      }
    }
    ${taxonomyTerms}
    ${primaryTag} 
  }
`;

export const archiveAudioFragment = gql`
  fragment ArchiveAudioFields on Audio {
    ${globalPostFields}
    ${featuredImageFields}
    focalPointX
    focalPointY
    readingTime
    contentType {
      node {
        graphqlSingleName
      }
    }
    ${taxonomyTerms}
    ${primaryTag} 
  }
`;

export const archivePodcastsFragment = gql`
  fragment ArchivePodcastFields on Podcast {
    ${globalPostFields}
    ${featuredImageFields}
    focalPointX
    focalPointY
    readingTime
    contentType {
      node {
        graphqlSingleName
      }
    }
    ${taxonomyTerms}
    ${primaryTag} 
  }
`;


export const archivePartnersFragment = gql`
  fragment ArchivePartnerFields on Partner {
    ${globalPostFields}
    ${featuredImageFields}
    focalPointX
    focalPointY
    readingTime
    contentType {
      node {
        graphqlSingleName
      }
    }
  }
`;

export const archiveConversationsFragment = gql`
  fragment ArchiveConversationFields on Conversation {
    ${globalPostFields}
    ${featuredImageFields}
    focalPointX
    focalPointY
    readingTime
    contentType {
      node {
        graphqlSingleName
      }
    }
    ${taxonomyTerms}
    ${primaryTag} 
  }
`;



export const archiveContent = `
  contentNodes(
    first: $first
    last: $last
    after: $after
    before: $before
    where: { orderby: { field: $orderBy, order: $order }}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ArchivePostFields
        ...ArchiveEventFields
        ...ArchiveVideoFields
        ...ArchiveAudioFields
        ...ArchivePodcastFields
      }
    }
  }
`;

export const archivePosts = `
  posts(
    first: $first
    last: $last
    after: $after
    before: $before
    where: { orderby: { field: $orderBy, order: $order }}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ArchivePostFields
      }
    }
  }
`;

export const archiveEvents = `
  events(
    first: $first
    last: $last
    after: $after
    before: $before
    where: { orderby: { field: $orderBy, order: $order }}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ArchiveEventFields
      }
    }
  }
`;

export const archiveVideos = `
  videos(
    first: $first
    last: $last
    after: $after
    before: $before
    where: { orderby: { field: $orderBy, order: $order }}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ArchiveVideoFields
      }
    }
  }
`;


export const archiveConversations = `
  conversations(
    first: $first
    last: $last
    after: $after
    before: $before
    where: { orderby: { field: $orderBy, order: $order }}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ArchiveConversationFields
      }
    }
  }
`;

export const archivePartners = `
  partners(
    first: $first
    last: $last
    after: $after
    before: $before
    where: { orderby: { field: $orderBy, order: $order }}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ArchivePartnerFields
      }
    }
  }
`;

