import BlockArticleCard from '@/components/BlockArticleCard';
import styles from './FeaturedTopic.module.scss';

export default function FeaturedTopic({ post }) {
  return (
    <div>
      <div className={styles.article__cards_title}>
        <h2>In Focus</h2>
      </div>
      <BlockArticleCard
        postType="post"
        postID={post.databaseId}
        columnSpan={5}
        cardType="in-focus"
      />
    </div>
  );
}
