import { gql } from '@apollo/client';
import defaultPageData from '@/lib/wp/_partials/defaultPageData';
import seoPostFields from '@/lib/wp/_partials/seoPostFields';
import {
  archivePostsFragment,
  archiveEventsFragment,
  archiveVideosFragment,
  archiveAudioFragment,
  archivePodcastsFragment,
  archiveContent,
} from '@/lib/wp/archives/queryArchives';

const queryPostsByTag = gql`
  query GET_POSTS_BY_TAG(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: PostObjectsConnectionOrderbyEnum = DATE
    $order: OrderEnum = DESC
    $imageSize: MediaItemSizeEnum = THUMBNAIL
    $id: ID!
    $idType: CategoryIdType = SLUG
  ) {
    ${defaultPageData}
    category(id: $id, idType: $idType) {
      ${seoPostFields}
      ${archiveContent}
    }
  }
  ${archivePostsFragment}
  ${archiveEventsFragment}
  ${archiveVideosFragment}
  ${archiveAudioFragment}
  ${archivePodcastsFragment}
`;

export default queryPostsByTag;
