import style from './BlockPostTitle.module.scss';
import cn from 'classnames';

const BlockPostTitle = ({ title, blockPostTitleStyle, className}) => {
  return (
    <div className={cn(style.main, className)} style={{ ...blockPostTitleStyle }}>
      <h2>{title}</h2>
    </div>
  );
};

export default BlockPostTitle;
