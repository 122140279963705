import BlockArticleCard from '@/components/BlockArticleCard';
import BlockArticleColumns from '@/components/BlockArticleColumns';
import BlockPostTitle from '@/components/BlockPostTitle';
import BlockTagTitle from '@/components/BlockTagTitle/BlockTagTitle';
import BlockTagCloud from '../BlockTagCloud/BlockTagCloud';
import BlockMediaCard from '../BlockMediaCard';
import Button from '@/components/Button';
import queryArticleByCategory from '@/lib/wp/taxonomy/queryArticlesByCategory';
import queryEventByCategory from '@/lib/wp/taxonomy/queryEventsByCategory';
import queryByCategory from '@/lib/wp/taxonomy/queryPostsByCategory';
import queryPostsByCompanyType from '@/lib/wp/taxonomy/queryPostsByCompanyType';
import queryPostsByDomain from '@/lib/wp/taxonomy/queryPostsByDomain';
import queryPostsByJobFunction from '@/lib/wp/taxonomy/queryPostsByJobFunction';
import queryPostsByJobLevel from '@/lib/wp/taxonomy/queryPostsByJobLevel';
import queryByTag from '@/lib/wp/taxonomy/queryPostsByTag';
import queryPostsByTopic from '@/lib/wp/taxonomy/queryPostsByTopic';
import queryVideos from '@/lib/wp/content/getVideos';
import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import style from './BlockPost.module.scss';

const BlockPost = ({ posts, pagination, taxonomy, term, termname }) => {
  const [results, setResults] = useState(posts);
  const [loadingMore, setLoadingMore] = useState(false);
  const [variables, setVariables] = useState({ id: term, first: 10 });
  const paginationRef = useRef(pagination);

  const {data} = useQuery(queryVideos)
  const media = data?.videos?.edges;

  const taxQuery = {
    category: queryByCategory,
    tag: queryByTag,
    domain: queryPostsByDomain,
    companytype: queryPostsByCompanyType,
    jobfunction: queryPostsByJobFunction,
    joblevel: queryPostsByJobLevel,
    topic: queryPostsByTopic,
    articlecategory: queryArticleByCategory,
    eventcategory: queryEventByCategory,
  };

  const { error } = useQuery(taxQuery[taxonomy], { variables, skip: !loadingMore, onCompleted: (loadedData) => {
    let loadedValue = loadedData?.[taxonomy]?.contentNodes?.edges;

    if (!Array.isArray(loadedValue)) {
      loadedValue = []
    }

    const resultsClone = [...results, ...loadedValue];
    setResults(resultsClone);
    paginationRef.current = loadedData?.[taxonomy]?.contentNodes?.pageInfo;
    setLoadingMore(false);
  } });

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  const loadPosts = () => {
    setLoadingMore(true);
    setVariables({...variables, after: paginationRef.current?.endCursor, ready: true});
  };

  useEffect(() => {
    if (variables.ready) {
      setLoadingMore(true);
    }
  }, [variables]);

  return (
    <div className={style.mainWrapper}>
      {taxonomy === "topic" ? '' : 
      <div>
        <BlockTagTitle term={termname } />
      </div>}
    <div className={style.post}>
      <div>
        <BlockPostTitle title="Top Results" />
        {results.map((item) => (
            <BlockArticleCard
              key={item?.node?.databaseId ?? item?.databaseId}
              postData={item?.node ?? item}
              postID={item?.node?.databaseId ?? item?.databaseId}
              postType={item?.node?.contentType?.node?.graphqlSingleName ?? item?.contentType?.node?.graphqlSingleName}
              cardType="list"
              columnSpan={6}
            />
        ))}
        <div className={style.loadMoreWrapper}>
          <pre>{paginationRef.current.hasNextPage}</pre>
          {paginationRef.current?.hasNextPage && (
            <Button onClick={loadPosts} disabled={loadingMore} text={loadingMore ? 'Loading...' : 'Load More'} />
          )}
        </div>
      </div>
      {/* Commenting out the media portion becuase there is no media to present for now */}
      {(media?.length > 0) &&
        <div className={style.mediaWrapper}>
          <BlockPostTitle title="Media" />
          {media?.map((item) => (
            <BlockMediaCard
              key={item?.node?.databaseId}
              postData={item.node}
              showImage = {false}
            />
          ))}

       {/* <BlockPostTitle blockPostTitleStyle={{ width: '230px', marginLeft: 25, marginTop:60}} title="Tags" />
        <BlockTagCloud />  */}
      </div> }
    </div>
 
        <BlockArticleColumns columns={4} manualSelect={false} title="More for you" showRecent={true} />
     
    </div>
  );
};

export default BlockPost;
