import style from './BlockTagTitle.module.scss';

const BlockTagTitle = ({term}) => {

  const cleanedTerm = term.replace(/-/g, ' ');

  return (
    <div className={style.main}>
      <p className={style.subtitle}>Tagged as:</p>
      <h2 className={style.title}>"{cleanedTerm}"</h2>
    </div>
  );
};

export default BlockTagTitle;

