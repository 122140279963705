import { gql } from '@apollo/client';
import defaultPageData from '@/lib/wp/_partials/defaultPageData';
import seoPostFields from '@/lib/wp/_partials/seoPostFields';
import { archiveEventsFragment, archiveEvents } from '@/lib/wp/archives/queryArchives';

const queryEventByCategory = gql`
  query GET_EVENTS_BY_CATEGORY(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: PostObjectsConnectionOrderbyEnum = DATE
    $order: OrderEnum = DESC
    $imageSize: MediaItemSizeEnum = THUMBNAIL
    $id: ID!
    $idType: EventcategoryIdType = SLUG
  ) {
    ${defaultPageData}
    eventcategory(id: $id, idType: $idType) {
      ${seoPostFields}
      ${archiveEvents}
    }
  }
  ${archiveEventsFragment}
`;

export default queryEventByCategory;
