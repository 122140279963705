import Blocks from '@/components/Blocks';
import Layout from '@/components/Layout';

/* eslint-disable react/prop-types */
import getPostTypeStaticPaths from '@/functions/wp/posts/getPostTypeStaticPaths';
import getPostTypeStaticProps from '@/functions/wp/posts/getPostTypeStaticProps';
import BlockPost from '@/components/BlockPost';
import Container from '@/components/Container';
import TopicHeader from '@/components/TopicHeader';
import FeaturedTopic from '@/components/FeaturedTopic';

const postType = 'post';

/**
 * Render the Post component
 *
 * @param  {object}  props       component attributes as props
 * @param  {object}  props.post  post data from WP
 * @param  {Array}   props.posts collection of posts from an archive
 * @return {Element}             Post component
 */
export default function Post(props) {
  
  const { post, posts, meta, pagination } = props;
  if (posts?.length) {
    return (
      <Layout seo={{ ...post?.seo }}>
        {!!meta?.topic && (
          <TopicHeader meta={meta} />
        )}
        {!!meta?.topic?.featuredArticle && (
          <Container>
            <FeaturedTopic post={meta?.topic?.featuredArticle} />
          </Container>
        )}
        <Container>
          <BlockPost posts={posts} taxonomy={meta?.taxonomy} term={meta?.term} pagination={pagination} termname={post?.seo?.breadcrumbs[1]?.text}/>
        </Container>
      </Layout>
    );
  }
  return (
    <Layout seo={{ ...post?.seo }}>
      <Blocks blocks={post?.blocks} />
    </Layout>
  );
}

/**
 * Get post static paths.
 *
 * @return {object} Array of paths and fallbacks
 */
export async function getStaticPaths() {
  return getPostTypeStaticPaths(postType);
}

/**
 * Get post static props
 *
 * @param  {object} context             context for current post
 * @param  {object} context.params      route parameters for current post
 * @param           context.preview
 * @param           context.previewData
 * @param           context.preview
 * @param           context.previewData
 * @return {object}                     post props
 */
export async function getStaticProps({ params, preview, previewData }) {
  return getPostTypeStaticProps(params, postType, preview, previewData);
}
