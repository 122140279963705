import { HiPlay } from 'react-icons/hi';
import cn from 'classnames';
import Moment from 'react-moment';
import style from './BlockMediaCard.module.scss';

const renderTags = (tags) => {
  let firsttag = data?.[postType]?.primaryTag?.primaryTag || tags?.find((tag) => tag.__typename === 'Tag');
  return (
    <>
      <div className={cn(style.subheader, 'ibm-plex-sans')}>
        {firsttag?.name}
      </div>
    </>
  );
};

const BlockMediaCard = (postData, showImage) => {
  const post = postData.postData
  if (showImage === true) {
    return (
      <div className={style.postcard}>
        <a href={`${post?.uri}?id=${post.databaseId}`}>
          <img src={post?.featuredImage?.node?.sourceUrl} className={style.image1} alt="" />
          <div className={style.rowitems}>
            {/* {renderTags(tags)} */}
            <div className={cn(style.title, 'paralucent')}>{post?.title}</div>
            <div className={cn(style.subtitle, 'ibm-plex-sans')}>
              <Moment format="MMM DD YY">
                {post?.date}
              </Moment>{' '}
              | {post?.duration} read
            </div>
          </div>
        </a>
        <div className={style.underline}></div>
      </div>
    );
  } else {
    return (
      <div className={style.postcard}>
        <div className={style.novideo}>
          <div className={style.rowitems}>
            <a href={`${post?.uri}?id=${post.databaseId}`}>
              <div className={cn(style.title, 'paralucent')}>{post?.title}</div>
              <div className={cn(style.subtitle, 'ibm-plex-sans')}>
                <Moment format="MMM DD YY">
                  {post?.date}
                </Moment>{' '}
                | {post?.duration} read
              </div></a>
          </div>
          <div>
            <HiPlay style={{ height: 50, width: 50 }} />
          </div>
        </div>
        <div className={style.underline}></div>
      </div>
    );
  }
};

export default BlockMediaCard;
