import { gql } from '@apollo/client';
import postCardFields from '@/lib/wp/_partials/postCardFields';
import videoFields from '@/lib/wp/_partials/videoFields';
import featuredImageFields from '@/lib/wp/media/featuredImage'


const queryVideos = gql`
query GET_ARCHIVE_VIDEOS(
  $first: Int,
  $orderBy: PostObjectsConnectionOrderbyEnum = DATE
  $order: OrderEnum = DESC
  $imageSize: MediaItemSizeEnum = MEDIUM
) {
  videos(
    first: $first
    where: { orderby: { field: $orderBy, order: $order }}
  ) {
    edges {
        node {
          id
          title
          date
          uri
          slug
          databaseId
          ${featuredImageFields}
          ${videoFields}
        }
    }
 }
}
`

export default queryVideos;