import Container from "@/components/Container";
import styles from './TopicHeader.module.scss';

export default function TopicHeader({ meta }) {
  return (
    <div className={styles.topic__header}>
      <Container className={styles.topic__container}>
        <div className={styles.topic__meta}>
          <h3 className={styles.topic__label}><span>Topic:</span></h3>
          <h1 className={styles.topic__name}>{meta?.name}</h1>
          <p className={styles.topic__description}>{meta?.description}</p>
        </div>
        <div className={styles.topic__image_wrapper}>
          <img className={styles.topic__image} src={meta?.topic?.image?.sourceUrl} alt={meta?.topic?.image?.altText} />
        </div>
      </Container>
    </div>
  )
}
